@import url(https://fonts.googleapis.com/css2?family=Cairo:wght@300&family=Quicksand&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Cairo', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* App.css */
.App {
  text-align: center;
  font-family: 'Cairo', sans-serif;

  input[type='text'],
  input[type='password'],
  input[type='date'],
  select {
    color: black;
    background-color: white;
  }
}

.dark {
  background-color: #222;
  color: #fff;
}

.App-header {
  background-color: #282c34;
  padding: 5px;
  min-height: 52px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-logo {
  height: 80px;
  pointer-events: none;
}

.credentials {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

h2 {
  margin-top: 0;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

label {
  font-weight: bold;
  margin-bottom: 5px;
}

input[type='text'],
input[type='password'],
input[type='date'] {
  padding: 5px;
  margin-bottom: 10px;
  width: 100%;
  text-align: center;
}

button[type='submit'] {
  padding: 10px 20px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button[type='submit']:hover {
  background-color: #45a049;
}

nav {
  background-color: #4caf50;
  padding: 10px;
  position: relative; /* Add this line */
  top: -70px; /* Adjust this value to lift the navbar up */
}


.nav-menu {
  list-style: none;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  background-color: #4caf50;
}

.nav-menu li {
  margin-right: 10px;
  background-color: #4caf50;
}

.nav-menu li:last-child {
  margin-right: 0;
  background-color: #4caf50;
}

.nav-menu a {
  color: #fff;
  text-decoration: none;
  padding: 5px 10px;
  background-color: #4caf50;
}

.nav-menu a:hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  
}

.active {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
}

.dark-mode-button {
  background-color: #fff;
  color: #4caf50;
  border: none;
  padding: 5px 10px;
  margin-top: 10px;
  cursor: pointer;
}

/* react-datepicker styles */
.react-datepicker-popper {
  z-index: 1;
}

.react-datepicker {
  font-family: 'Josefin Sans', sans-serif;
  border: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.react-datepicker__header {
  background-color: #4caf50;
  color: #fff;
}

.react-datepicker__day {
  color: #000;
}

.react-datepicker__day--selected {
  background-color: #4caf50;
  color: #fff;
}

.react-datepicker__day--selected:hover {
  background-color: #45a049;
}

.react-datepicker__day--keyboard-selected {
  background-color: #45a049;
}

/* Dark mode styles */

.dark-mode {
  background-color: #333;
  color: #fff;
}

.dark-mode .App-header {
  background-color: #000;
}

.dark-mode .credentials {
  background-color: #333;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
}

.dark-mode .credentials h2 {
  color: #fff;
}

.dark-mode form label {
  color: #fff;
}

.dark-mode input[type='text'],
.dark-mode input[type='password'],
.dark-mode input[type='date'] {
  background-color: #555;
  color: #fff;
}

.dark-mode button[type='submit'] {
  background-color: #4caf50;
  color: #fff;
}

.dark-mode nav {
  background-color: #000;
}

.dark-mode .nav-menu a {
  color: #fff;
}

.dark-mode .nav-menu a:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.dark-mode .active {
  background-color: rgba(0, 0, 0, 0.1);
}

.dark-mode .dark-mode-button {
  background-color: #4caf50;
  color: #fff;
}

/* Add dark mode styles for react-datepicker */
.dark-mode .react-datepicker-popper .react-datepicker {
  background-color: #333;
  border-color: #555;
}

.dark-mode .react-datepicker-popper .react-datepicker__current-month {
  color: #fff;
}

.dark-mode .react-datepicker-popper .react-datepicker__day-name,
.dark-mode .react-datepicker-popper .react-datepicker__day,
.dark-mode .react-datepicker-popper .react-datepicker__time-name {
  color: #fff;
}

.dark-mode .react-datepicker-popper .react-datepicker__day:hover,
.dark-mode .react-datepicker-popper .react-datepicker__month-text:hover,
.dark-mode .react-datepicker-popper .react-datepicker__quarter-text:hover,
.dark-mode .react-datepicker-popper .react-datepicker__year-text:hover {
  background-color: #555;
  color: #fff;
}

.dark-mode .react-datepicker-popper .react-datepicker__day--selected,
.dark-mode .react-datepicker-popper .react-datepicker__day--in-selecting-range,
.dark-mode .react-datepicker-popper .react-datepicker__day--in-range {
  background-color: #4caf50;
  color: #fff;
}

/* Responsive Styles */

@media screen and (max-width: 600px) {
  .credentials {
    width: 90%;
  }
}

.ios-calendar {
  display: inline-block;
  font-size: 16px;
  font-family: Arial, sans-serif;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  color: #000;
  padding: 10px;
  cursor: pointer;
}

.dark-mode .react-datepicker__input-container input {
  color: #fff;
  background-color: #555;
}

.dark-mode .react-datepicker__day {
  color: #fff !important;
}

.dark-mode .react-datepicker__header {
  background-color: #000;
  color: #fff;
}

.dark-mode .react-datepicker__day--selected {
  background-color: #4caf50 !important;
  color: #fff !important;
}

.dark-mode .ios-calendar {
  background-color: #555;
  color: #fff;
}

.react-datepicker__input-container,
.react-ios-time-picker-input {
  text-align: center;
}


.custom-input {
  text-align: center;
  background-color: #ffffff;
  color: #000000;
  /* add other styling as needed */
}

.date-picker-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  right: 50px;
}

.react-datepicker-ignore-onclickoutsid{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}



.home-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.welcome-message {
  font-style: italic;
  color: #555;
}

.user-profile-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ccc;
  padding: 20px;
  margin: 20px;
  border-radius: 10px;
}

.user-profile-card img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.daily-quote {
  font-size: 18px;
  text-align: center;
  margin: 20px;
}

.weather-widget {
  font-size: 16px;
  text-align: center;
  margin: 10px;
}
.bold-text {
  font-weight: bold;
}

.navbar {
  background-color: #4caf50; /* Your desired green color */
  /* Other styles... */
  
}


.welcome-container {
  margin-top: 20px; /* Adjust this value as needed */
}


@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.rotate-logo {
  -webkit-animation: rotate 5s linear infinite;
          animation: rotate 5s linear infinite;
  height: 80px;
  pointer-events: none;
}

